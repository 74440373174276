<div class="w3-container ">

      <form class="w3-container contact" [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)"><div class="form-group">
          <h1 class="w3-center">LETS TALK</h1>
          <h3 class="w3-center  text-message w3-text-white">We would love to hear from you!</h3>
          <br>
          <div class="w3-row ">
              <div class="w3-col icon" ><i class=" fa fa-user"></i></div>
              <div class="w3-rest ">
                
                <input class=" input w3-border form-control" type="text"  name="Fullname" placeholder="Full Name" formControlName="Fullname">
              </div>
          </div>
          <div class="w3-row ">
              <div class="w3-col icon"><i class=" icon-size fa fa-briefcase"></i></div>
              <div class="w3-rest">
                <input class=" input w3-border form-control" type="text"  name="Company" aria-describedby="Company" placeholder="Company"formControlName="Company">
              </div>
          </div>
          <div class="w3-row ">
              <div class="w3-col icon"><i class=" fa fa-address-book-o"></i></div>
              <div class="w3-rest">
                <input class=" input w3-border form-control" type="number"  name="phone" aria-describedby="phoneNumber" placeholder="Contact Number"formControlName="Contact">
              </div>
          </div>
          
          <div class="w3-row ">
              <div class="w3-col icon"><i class=" fa fa-envelope-o"></i></div>
              <div class="w3-rest">
                <input class=" input w3-border form-control" type="email"  name="Email" aria-describedby="emailHelp" placeholder="Email"formControlName="Email">
              </div>
          </div>

          <div class="w3-row ">
              <div class="w3-col icon"><i class="fa fa-pencil"></i></div>
              <div class="w3-rest">
                <textarea rows="5" cols="60" class=" input textarea w3-border form-control" formControlName="Comment" name="Comment" type="text" placeholder="Message">
                </textarea>
              </div>
          </div>
          <div class="w3-center ">
           <button type="submit" class="w3-button subbutton" [disabled]="!FormData.valid">Submit</button>
           </div>
          

  </div>

 