import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tempory-holder',
  templateUrl: './tempory-holder.component.html',
  styleUrls: ['./tempory-holder.component.css']
})
export class TemporyHolderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
