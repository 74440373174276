<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="preconnect" href="https://fonts.gstatic.com">
<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Asap:wght@400;600&family=Baloo+Tamma+2:wght@400;500&family=Balsamiq+Sans:wght@400;700&family=Comfortaa:wght@300;400;500;600;700&display=swap" rel="stylesheet">

<style>


</style>
<body >
    <div class="bg w3-display-container">
       <!-- <img src="./assets/Header.png" style="width:100%;"> -->
        <img class="w3-diplay-topmiddle" src="./assets/BG.png" style="width:100vw; ">

        <div class="front w3-display-topleft" style="width:100%;">
          <div class="w3-container" >
            <!--HEADER-->
            <div class="w3-bar">
              
              <!-- Right-sided navbar links. Hide them on small screens -->
              <div  class="w3-right  navbar">
                <a @fade href="#about" class="w3-bar-item w3-text-white w3-hover-blue w3-button">ABOUT US</a>
                <a @fade href="#events" class="w3-bar-item w3-text-white w3-hide-small w3-hover-blue w3-button">UPCOMING EVENTS</a>
                <a @fade href="#clients" class="w3-bar-item w3-text-white w3-hide-small w3-hover-blue w3-button">PAST CLIENTS</a>
                <a @fade href="#contact" class="w3-bar-item w3-text-white w3-hover-blue w3-button">CONTACT US</a>
              </div>
            </div>


            <div style="width:100%;">

              <!-- Title Image -->
              <div >
                  <div class="right" > 
                    <img @fade class="title-img"  src="./assets/Title.png" > 
                  </div>
              </div>
              <br>
              <!--WHO ARE WE-->
              <div id="about" @fade class="section-1"  >
                <div>
                <h1> WHO ARE WE </h1>
                <p> Samantota Promotions is a 100% black female owned company. 
                  The name Samantota, pronounced Sama-n-tota is a colloquial term in the Tswana language that denotes real. It is a term that denotes substance and existence and is used in concurrence on a subject matter. 
                </p>
                
                <p> </p>
                
                <p class="w3-hide-small">
                  Samantota Promotions is a music marketing agency which applies fresh and innovative approaches to music entertainment brands.  We are driven by the passion to provide unique and flawless music experiences through artist brand management and marketing communications.  Sound Entertainment, because we provide considered sound solutions!
                </p>
              </div>
            </div>
              <!--OUR SERVICES-->
              <div id="services" @fade class="section-2"  >
                <h1 class="w3-text-white"> OUR SERVICES </h1>
                <div>
                  <p> </p>
                <img  class="services" src="./assets/Services.png" > 
                <br> 
                <p> </p>
                <a href="#contact" class="my-button w3-hover-grey w3-center right">Find Out More</a>
              </div>
            </div>
              <!--SOME OF OUR CLIENTS-->
              <div id="clients" @fade class=" section-3 "  >
                <div>
                <h1 class="w3-center"> SOME OF OUR CLIENTS </h1>
                <p class="w3-center"> We take pride in our various clients and the promotions we created for them.</p>

                <div class="w3-center">
                    <img  class="clients-img" src="./assets/Group21.png" >
                </div>
              </div>
              </div>
              <!--EVENTS-->
              <div id="events" @fade class=" section-4" >
                  <div>
                    <h1 class="w3-center"> EVENTS </h1>
                    <br class="w3-hide-small">
                    <div class="w3-panel w3-round-xlarge orange w3-padding">
                      <img  class="events" src="./assets/exp.png" >
                      <h1>The Show Room Experience</h1>
                      <p > The Showroom Experience is an innovatice music platform which hosts music experiences in innovative spaces.</p>
                      
                      <a href="https://www.youtube.com/channel/UC1HGDDaz9iQ7JdouHPMKSUw" class="my-button-2 w3-hover-grey w3-center right">Find Out More</a>
                    </div>
                </div>
              </div>

              <!--Contact Form-->
              <div id="contact" @fade class="w3-margin section-5" >
                <div>
                  <app-contact-form></app-contact-form>
                </div>
              </div>
            </div>
            

          </div>
          
          
        </div>
        
    </div>
    
    

</body>